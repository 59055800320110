.ItemContainer {
  display: flex;
  width: 20%;
  max-height: 410px;
  margin: 15px;
  flex-direction: column;
  border: 2px solid black;
}

.POneJobeEmpresa {
  overflow: auto;
  max-height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid black;
  padding: 10px;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.POneJobeEmpresa::-webkit-scrollbar {
  width: 7px;
}


.POneJobeEmpresa::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 5px;
}

.PCVButton {
  min-height: 10%;
  margin: 0px;
  padding: 0px;
  align-content: center;
  text-align: center;
  word-wrap: break-word;
}

.PPhotoStudent {
  max-width: 30%;
  align-self: center;
  margin-bottom: 20px;
}

.spanItemJob {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}


.list-students-section ul {
  padding-left: 0;
}

.div-paginate-student-numbers {
  position: relative;
  display: flex;
  align-items: center;
}

.PDTENumeroDeEstudiantes {
  position: absolute;
}

.videoBox {
  width: 410px;
  height: 290px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 50%;
}

.videoCardMedia {
  width: 100%;
  border: none;
  cursor: pointer;
  height: 100%;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

#grid-buttons-english {
  flex-wrap: nowrap;
}

#grid-buttons {
  width: auto;
}
