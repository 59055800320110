.PDTEOneJob {
  display: flex;
  justify-content: center;
  border-radius: 15px;
  margin-top: 24px;
  border: 2px solid black;
  flex-direction: column;
  padding: 10px;
}

.PDTEOneJob:hover {
  cursor:pointer;
  transform: scale(1.05);
}

.EstadoDePostulación {
  color: rgb(77, 247, 77);
}

#quill-html-container-pre-student p{
  font-size: 16px;
  margin-bottom: 0;
}