@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");


#copyright .row>* {
    flex-shrink: 0;
    width: auto;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }

#copyright {
    background: #1b0c61;
}

#copyright li a{
  text-decoration: none;
}

#copyright.padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

#copyright .list-group-item,
#copyright .list-group-item a {
    background: transparent;
    color: #fff;
    border: 0;
}

#copyright .list-group-item.text {
    padding: 0
}

#copyright .list-group:last-of-type .list-group-item:last-of-type {
    position: relative;
}

#copyright .list-group:last-of-type .list-group-item:last-of-type:before {
    content: "";
    width: 1px;
    height: 30%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

#copyright-ul {
    color: #fff;
    text-decoration: none;
}

#copyright-ul a{
    color: #fff;
    text-decoration: none;
    margin-left: 3px;
}

@media screen and (min-width: 1920px) {
    .header-wrap,
    main,
    footer,
    #copyright {
        max-width: 1920px;
        margin: auto;
    }
}

@media screen and (max-width: 991px) {
    #copyright .list-group-item,
    #copyright .list-group-item a {
        font-size: 16px;
    }
}