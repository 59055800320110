@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

img {
    max-width: 100%;
}

.btn {
    position: relative;
}

a {
    transition: 200ms linear;
}

a:hover {
    text-decoration: none;
}

.navbar-dark .navbar-toggler {
    color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.container {
    max-width: 1440px;
    width: 95%;
}

.bg-degrade {
    background-color: #e33b3a;
    background: -moz-linear-gradient( top, rgba(255, 56, 55, 1) 0%, rgba(173, 44, 44, 1) 100%);
    background: -webkit-gradient( left top, left bottom, color-stop(0%, rgba(255, 56, 55, 1)), color-stop(100%, rgba(173, 44, 44, 1)));
    background: -webkit-linear-gradient( top, rgba(255, 56, 55, 1) 0%, rgba(173, 44, 44, 1) 100%);
    background: -o-linear-gradient( top, rgba(255, 56, 55, 1) 0%, rgba(173, 44, 44, 1) 100%);
    background: -ms-linear-gradient( top, rgba(255, 56, 55, 1) 0%, rgba(173, 44, 44, 1) 100%);
    background: linear-gradient( to bottom, rgba(255, 56, 55, 1) 0%, rgba(173, 44, 44, 1) 100%);
}

.bg-purple {
    background: #112959;
}

.text-red {
    color: #e33b3a;
}

.text-purple {
    color: #112959;
}

.bg-red {
    background-color: #e33b3a;
}

.bg-tred {
    background: #ff003c;
}

.btn-red {
    border-color: #e33b3a;
    background-color: #e33b3a;
    color: #ffffff !important;
}

.btn-red:hover {
    border-color: #e33b3a;
    background-color: #ce3938;
    color: #ffffff !important;
}

.btn-outline-red {
    border-color: #e33b3a;
    background-color: #ffffff;
    color: #e33b3a !important;
}

.btn-outline-red:hover {
    border-color: #e33b3a;
    background-color: #e33b3a;
    color: #ffffff !important;
}

.btn {
    border-radius: 0;
    padding: 0.75rem 1rem;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.call .btn-secondary:not(:disabled):not(.disabled).active,
.call .btn-secondary:not(:disabled):not(.disabled):active,
.call .show>.btn-secondary.dropdown-toggle {
    background: #1b0c61;
    box-shadow: none;
}

main {
    background: #fff;
}

.header-wrap {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background-color: transparent;
    padding: 25px 0;
    position: sticky;
}

.header-wrap .container {
    max-width: 1680px;
}

.header-wrap .navbar {
    border-radius: 30px;
    font-size: 15px;
    padding: 0 0;
    width: 100%;
}

.box-shadow {
    border: none;
    -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.15);
}

.padding {
    padding-bottom: 80px;
}

.navbar-brand {
    margin-right: 45px;
}

@media screen and (min-width: 991px) {
    #navbarHolberton {
        margin-top: -18px;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-size: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 0 20px;
    letter-spacing: 0;
    font-weight: 400;
}

.navbar-dark .navbar-nav .nav-link>i {
    margin-left: 8px;
}

.navbar-dark .dropdown-item.active,
.navbar-dark .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff003b;
}

.navbar-dark .dropdown-item:focus,
.navbar-dark .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #ff003b;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: rgb(255 0 60);
}

#navbarHolberton .dropdown-menu {
    left: 20px;
    padding: 0.5rem 0;
    margin: 0.5rem 0 0;
    border-radius: 5px;
}

.fixed-top {
    transition: 200ms linear;
}

.scrolled {
    background: #1b0c61;
    transition: 200ms linear;
}

.btn {
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 20px;
    margin-right: 10px;
}

.btn-danger {
    background: #ff003c;
}

.call {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.call .btn {
    border-radius: 5px;
    padding: 5px 30px;
    font-size: 15px;
}

.call .btn.btn-danger {
    padding: 7px 30px;
    line-height: normal;
    font-weight: 500;
}

.call .btn.btn-light {
    padding: 7px 20px;
    line-height: normal;
    font-weight: 500;
}

.call .btn.dropdown-toggle {
    background: transparent;
    color: #fff;
    border: 0;
    padding: 8px 15px;
    margin-right: 0;
}

#navbarHolberton .call .dropdown-menu {
    left: 0;
    width: 100%;
    min-width: 100%;
}

#navbarHolberton .call .dropdown-menu a {
    padding: 0 10px;
    text-align: center;
}

.scrolled .call .dropdown-toggle {
    color: #fff;
}

#slider {
    position: relative;
    overflow: hidden;
}

#slider .title {
    color: #fff;
    font-size: 63px;
    font-weight: 900;
    margin-bottom: 45px;
}

#slider p.subtitle {
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 30px;
    line-height: normal;
    max-width: 90%;
}

#slider p.info {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 30px;
}

#slider p>img {
    margin-right: 10px;
}

#slider .btn-shaddai {
    margin-top: 30px;
}

#slider .btn-shaddai i {
    margin-left: 15px;
}

.head {
    padding-bottom: 50px;
    text-align: center;
}

.head h2 {
    font-weight: 600;
    color: #292728;
    margin-bottom: 0;
    font-size: 45px;
}

.head span {
    position: relative;
    display: inline-block;
    font-weight: 500;
    color: #ff003c;
    font-size: 25px;
}

#slider .carousel-item .carousel-caption {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: left;
    width: 95%;
}

#slider .carousel-item .carousel-caption .col-lg-6 {
    margin-top: -130px;
}

#slider .btn {
    padding: 20px 80px;
    margin: 0;
}

#testimonials .testimonial {
    position: relative;
    width: 60%;
    margin: 30px auto 30px;
    background: #fff;
    padding: 60px 50px;
    border-radius: 5px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

#testimonials .testimonial .figure-img {
    position: absolute;
    right: 25px;
    top: 25px;
    margin-bottom: 0;
}

#testimonials .testimonial .figure:before {
    content: "\f10d";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    display: block;
    text-align: center;
    color: #ff003c;
    font-size: 25px;
    margin-bottom: 20px;
}

#testimonials .testimonial:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    text-align: center;
    color: #fff;
    font-size: 80px;
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    line-height: 0;
    transform: translateX(-50%);
    display: none;
}

#testimonials .testimonial .figure p {
    font-weight: 400;
    color: #292728;
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
}

#testimonials .testimonial .figure-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#testimonials .testimonial .figure-caption img {
    margin-right: 15px;
}

#testimonials .testimonial .figure-caption .text-info {
    text-align: left;
}

#testimonials .testimonial .figure-caption .text-info h3 {
    font-weight: 900;
    color: #ff003c;
    font-size: 20px;
    margin-bottom: 5px;
}

#testimonials .testimonial .figure-caption .text-info p {
    font-size: 16px;
    margin-bottom: 0;
}

#countries {
    background: #ff003c;
}

#countries .head {
    text-align: left;
}

#countries .head h2 {
    color: #fff;
    margin-bottom: 30px;
}

#countries .head span {
    margin-bottom: 30px;
    color: #fff;
}

#countries .head p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
}

#news .item {
    margin-bottom: 30px;
}

#news .item img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 200ms linear;
}

#news .item img:hover {
    transform: scale(1.05);
    transition: 200ms linear;
}

#contact .container .row {
    padding: 50px 0;
    position: relative;
}

#contact .container .row:before {
    content: "";
    background: #ff003c;
    width: 75%;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

#contact img {
    border-radius: 10px;
}

#contact .head {
    text-align: left;
    width: 95%;
    margin: 0 auto 0 0;
}

#contact .head h2 {
    color: #fff;
    margin-bottom: 20px;
}

#contact .head p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
}

#copyright {
    background: #1b0c61;
}

#copyright.padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

#copyright .list-group-item,
#copyright .list-group-item a {
    background: transparent;
    color: #fff;
    border: 0;
}

#copyright .list-group:last-of-type .list-group-item:last-of-type {
    position: relative;
}

#copyright .list-group:last-of-type .list-group-item:last-of-type:before {
    content: "";
    width: 1px;
    height: 30%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

#methodology .nav-methodology .nav-tabs {
    justify-content: center;
    margin-bottom: 100px;
    border-bottom: 0px solid #dee2e6;
    position: relative;
}

#methodology .nav-methodology .nav-tabs:after {
    content: "";
    width: 60%;
    background: #eaeaea;
    height: 5px;
    position: absolute;
    bottom: -60px;
}

#methodology .nav-methodology .nav-tabs .nav-link {
    position: relative;
    font-weight: 900;
    font-size: 20px;
    width: 30%;
    text-align: center;
    color: #292728;
}

#methodology .nav-methodology .nav-tabs .nav-link>span {
    display: block;
    font-size: 32px;
}

#methodology .nav-methodology .nav-tabs .nav-link:after {
    content: url(../../images/nav-icon.png);
    display: block;
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

#methodology .nav-methodology .nav-tabs .nav-item.show .nav-link,
#methodology .nav-methodology .nav-tabs .nav-link.active,
#methodology .nav-methodology .nav-tabs .nav-link:focus,
#methodology .nav-methodology .nav-tabs .nav-link:hover {
    border: 0;
}

#methodology .nav-methodology .nav-tabs .nav-link.active:after {
    content: url(../../images/nav-icon2.png);
    transition: 200ms linear;
    bottom: -85px;
}

#methodology .tab-content h3 {
    position: relative;
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 50px;
}

#methodology .tab-content h3:after {
    content: "";
    width: 50px;
    height: 5px;
    background: #e1003c;
    position: absolute;
    left: 0;
    bottom: -15px;
}

#methodology .tab-content ul li {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: justify;
}

#methodology .tab-content ul li::marker {
    color: #e1003c;
}

.jvectormap-container {
    background-color: rgb(255 0 60) !important;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
    display: none !important;
}

#world-map {
    width: 100%;
    height: 400px;
}

footer {
    background: #fff;
}

footer h4 {
    margin-bottom: 30px;
    color: #ff003c;
    font-weight: 900;
    font-size: 24px;
}

footer .col-lg-3:first-of-type h4 {
    color: #292728;
}

footer .input-group>.input-group-prepend>.form-control {
    height: 50px;
    border: 0;
    background: #f1f2f2;
}

footer .input-group>.input-group-prepend>.btn.btn-danger {
    margin-right: 0;
    margin-left: 10px;
    padding: 0 15px;
    border-radius: 5px !important;
    color: #fff;
    height: 50px;
    width: 50px;
    border: 0;
}

footer ul {
    padding-inline-start: 0;
    list-style: none;
}

footer ul li {
    margin-bottom: 20px;
    font-weight: 300;
}

footer ul li a {
    color: #292728;
}

footer ul li a:hover {
    color: #ff003c;
}

footer .col-lg-3:last-of-type i {
    font-size: 16px;
}

footer ul.social-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

footer ul.social-info li a {
    width: 50px;
    height: 50px;
    display: flex;
    background: #ff003c;
    margin-right: 10px;
    color: #fff !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.swiper-button-prev,
.swiper-button-next {
    color: #1b0c61;
}


/*#map_inner div:nth-child(2)>a {
    position: relative;
}

#map_inner div:nth-child(2)>a:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #ff003c;
    position: absolute;
    top: 0;
    left: 0;
}*/

.next-1,
.next-2 {
    position: absolute;
    top: 50%;
    color: #fff;
    background: #ff003c;
    right: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.prev-1,
.prev-2 {
    position: absolute;
    top: 50%;
    color: #fff;
    background: #ff003c;
    left: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.next-1:hover,
.next-2:hover,
.prev-1:hover,
.prev-2:hover {
    color: #fff;
}

.breadcrumb {
    padding: 0 0 0 0;
    margin-bottom: 0;
    background: #fff;
    position: relative;
}

.breadcrumb .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.breadcrumb .head {
    padding: 0;
}

.breadcrumb .head .title {
    color: #fff;
    font-size: 45px;
}

.breadcrumb .head span {
    color: #e1003c;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
}

.page #contact .container .row {
    padding: 0 0;
}

.page #contact .container .row:before {
    display: none;
}

.page #contact .head {
    width: 100%;
    margin: 0 0 0 0;
    padding-bottom: 0;
    padding: 80px 0;
}

.page #contact .bg-tred {
    border-radius: 5px;
}

.page-contact ul {
    padding-inline-start: 0;
    list-style: none;
}

.page-contact ul li {
    margin-bottom: 20px;
    font-weight: 300;
}

.page-contact ul li a {
    color: #292728;
}

.page-contact ul li a:hover {
    color: #ff003c;
}

.page-contact .col-lg-3:last-of-type i {
    font-size: 16px;
}

.page-contact ul.social-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    margin-top: 15px;
}

.page-contact ul.social-info li a {
    margin-right: 10px;
    color: #2b354f;
    font-size: 20px;
}

.form-contact input,
.form-contact .custom-file,
.form-contact .custom-file-label {
    height: 50px;
}

.form-contact input,
.form-contact textarea {
    background: #f4f4f4;
    border-color: #f4f4f4;
}

.form-contact .custom-file-label {
    text-align: center;
    padding: 12px 10px;
}

.form-contact .custom-file-label span {
    color: #ff003c;
}

.form-contact .custom-file-label::after {
    display: none;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
}

.label-text {
    font-weight: 600;
    color: #000000;
}

.form-contact .form-row:nth-child(3) .form-group {
    margin-bottom: 20px;
}

.form-contact .form-row:nth-child(4) .form-group {
    margin-bottom: 30px;
}

.form-contact .btn-danger {
    padding: 15px 50px;
}

.info {
    margin-bottom: 50px;
}

.info h4 {
    color: #e1003c;
}

.page-aboutus .breadcrumb .head .title {
    max-width: 70%;
    margin: auto;
    font-size: 32px;
}

.page-aboutus .breadcrumb .head span {
    font-size: 28px;
}

#start .head {
    text-align: left;
    padding-top: 50px;
    width: 80%;
}

#start .head h2 {
    margin-bottom: 30px;
    margin-top: 10px;
}

#start .head p {
    color: #292728;
    font-size: 18px;
    font-weight: 300;
    text-align: justify;
}

.page-aboutus #countries {
    background: #f4fcff;
}

.page-aboutus #countries .head span {
    color: #ff003c;
}

.page-aboutus #countries .head h2,
.page-aboutus #countries .head p {
    color: #292728;
}

.page-aboutus #countries .box-shadow {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#ourteam .swiper-slide:nth-child(2n) {
    padding-top: 45px;
}

#ourteam .text-info {
    margin-top: 20px;
}

#ourteam img {
    border-radius: 10px;
}

#ourteam .text-info h3 {
    font-size: 22px;
    color: #e1003c;
    margin-bottom: 5px;
}

#ourteam .text-info p {
    color: #292728;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 16px;
}

#ourteam .swiper-pagination {
    position: relative;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    left: unset;
    transform: none;
    margin: 30px auto 0;
}

#ourteam .swiper-pagination .swiper-pagination-bullet {
    width: var( --swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px));
    height: var( --swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 12px));
    background: #292728;
    opacity: 1;
}

#ourteam .swiper-pagination .swiper-pagination-bullet-active {
    background: #e1003c;
}

.page #testimonials {
    background: #f4fcff;
}

.page #testimonials .testimonial {
    width: 100%;
}

.breadcrumb.v2 .head {
    text-align: left;
}

.breadcrumb.v2 .head .title {
    font-size: 45px;
    max-width: 90%;
}

.breadcrumb.v2 .head h3 {
    color: #fff;
    font-size: 18px;
}

.breadcrumb.v2 .head p {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin: 10px 0 40px;
}

#awards .text-info {
    text-align: center;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    padding: 40px 30px;
}

#awards .text-info h3 {
    color: #ff003c;
    font-weight: 700;
    font-size: 45px;
}

#awards .text-info p {
    font-size: 20px;
    color: #292728;
    margin-bottom: 0;
    font-weight: 400;
}

.page-becas #form .label-text {
    font-weight: 500;
    color: #464547;
}

.page-becas .form-contact .form-row .form-group {
    margin-bottom: 20px;
}

.page-becas .form-contact .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #e1003c;
    background-color: #e1003c;
}

.page-becas .form-contact .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem hsl(0deg 0% 0% / 15%);
}

.page-becas .form-contact .custom-radio .custom-control-input:checked~.custom-control-label::after {
    display: none;
}

#grant {
    background: #f4fcff;
}

#grant .head {
    padding-bottom: 80px;
}

#grant .text-info h3 {
    width: 50px;
    height: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

#grant .text-info p {
    color: #292728;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}

#grant p.message {
    color: #292728;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    text-align: center;
}

.page-adm .breadcrumb.v2 .head p {
    margin: 20px 0;
}

.page #process {
    background: #f4fcff;
}

#process .head {
    max-width: 45%;
    margin: 0 auto 0;
    padding-bottom: 80px;
}

#process .head h2 {
    margin-bottom: 20px;
}

#process .head p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0;
}

#need {
    background: #f4fcff;
}

#need .head h2 {
    margin-bottom: 20px;
}

#need .head ul li {
    font-size: 20px;
    font-weight: 300;
    color: #292728;
    padding-left: 10px;
}

#need .head ul li::marker {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    font-weight: 400;
    font-size: 18px;
    color: #e1023d;
}

#mode .head {
    max-width: 60%;
    margin: 0 auto 0;
}

#mode .head h2 {
    margin-bottom: 20px;
}

#mode .head p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0;
}

#mode .nav-tabs {
    justify-content: center;
    border: 0;
}

#mode .nav-tabs .nav-link {
    border: 0;
    text-align: center;
}

#mode .nav-tabs .nav-link span {
    border: 3px solid #e1003c;
    padding: 10px 25px;
    display: block;
    border-radius: 30px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    color: #e1003c;
}

#mode .nav-tabs .nav-link p {
    margin-bottom: 0;
    font-weight: 700;
    color: #292728;
}

#mode .nav-tabs .nav-link.active span {
    background: #e1003c;
    color: #fff;
}

#mode .tab-content {
    padding-top: 30px;
}

#mode .tab-content .col {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    padding: 10px 30px;
}

#mode .tab-content .col ul {
    padding-left: 0;
    list-style: none;
    text-align: center;
    margin-bottom: 0;
}

#mode .tab-content .col ul li {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 30px;
    padding-top: 30px;
}

#mode .tab-content .col ul li:after {
    content: "";
    width: 50%;
    height: 1px;
    background: #cccccc;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

#mode .tab-content .col ul li:last-of-type:after {
    display: none;
}

#mode .tab-content .col ul li:last-of-type small {
    margin: 10px 0;
}

#mode .tab-content .col ul li:last-of-type small.mt-0 {
    font-size: 12px;
}

#mode .tab-content .col ul li strong {
    font-weight: 700;
    display: block;
    margin: 5px 0;
}

#mode .tab-content .col ul li small {
    font-size: 14px;
    line-height: normal;
    display: block;
}

#mode .btn-danger {
    margin-right: 0;
    margin-top: 15px;
}

#schedule {
    background: #f4fcff;
}

#schedule .col-table {
    background: #fff;
    padding: 30px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

#schedule .table {
    margin-bottom: 0;
}

#schedule .table td,
#schedule .table th {
    border: 0;
    padding: 30px 30px 30px 30px;
}

#schedule .table tr {
    border-bottom: 1px solid #ededed;
}

#schedule .table strong {
    display: block;
}

#schedule .table p {
    margin-bottom: 0;
}

#schedule .table tr td:first-of-type strong {
    background: #ff003c;
    color: #fff;
    font-size: 20px;
    display: inline-block;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 5px;
}

#schedule .table tr td:first-of-type p {
    font-weight: 300;
    color: #292728;
    font-size: 15px;
}

.page-aboutus #contact,
.page-adm #contact,
.page-holbies #contact,
.page-career #contact,
.page-blog #contact {
    background: linear-gradient(0deg, #ffffff 50%, #f4fcff 50%);
}

.page-holbies #partners .head {
    text-align: center;
}

.page-holbies #partners .head h2 {
    margin-bottom: 0;
}

.col-xs-5col,
.col-sm-5col,
.col-md-5col,
.col-lg-5col {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.valorations {
    background: #f0f3f5;
    padding: 30px 30px;
    border-radius: 5px;
    text-align: center;
    margin-top: 50px;
}

.valorations h3 {
    font-size: 16px;
    color: #777;
}

.valorations ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}

.valorations ul li {
    display: inline-block;
    padding: 0 10px;
}

.valorations ul li a {
    font-size: 42px;
    color: #292728;
}

.valorations ul li a:hover {
    color: #ff003c;
}

.valorations ul li .rate.selected {
    color: #ff003c;
    font-size: 50px;
}

@media (min-width: 768px) {
    .col-sm-5col {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 992px) {
    .col-md-5col {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 1200px) {
    .col-lg-5col {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.page-holbies #partners {
    background: #f4fcff;
}

#skills {
    background: #e1003c;
}

#skills .head h2 {
    color: #fff;
}

#skills .col-skill {
    background: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 50px 30px;
    height: 100%;
}

#skills .col-skill .text-info h3 {
    color: #292728;
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
}

#skills .col-skill .text-info .ul-skills {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

#skills .col-skill .text-info ul {
    list-style: none;
    padding: 0;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#skills .col-skill .text-info ul:last-of-type {
    margin: 0 0 0 0;
}

#skills .col-skill .text-info ul li {
    color: #292728;
    font-weight: 300;
    text-align: left;
    padding-left: 3px;
    width: 40%;
    float: left;
}

#skills .col-skill .text-info ul li::marker {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    font-weight: 400;
    font-size: 18px;
    color: #e1023d;
}

#skills .col-skill .text-info ul li small {
    display: block;
    padding-left: 20px;
}

#skills .col-skill .text-info ul li i {
    color: #e1003c;
}

.page .breadcrumb.v2 .btn-danger {
    display: block;
    width: 40%;
}

.btn-download {
    display: block;
    width: 40%;
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
    padding: 0;
    margin: 10px 0 0;
}

.btn-download:hover {
    color: #ffff;
}

.page-carrera .breadcrumb.v2 .head h2 {
    margin-top: 10px;
    font-size: 65px;
}

.page-carrera .breadcrumb.v2 .head p {
    margin: 30px 0 35px;
}

#career {
    background: #f4fcff;
    padding: 50px 0;
}

#career .head {
    padding-bottom: 0;
}

#career .head h2 {
    font-size: 35px;
}

#jobs .col-job {
    padding: 30px 30px;
    border-bottom: 2px solid #efefef;
}

#jobs .col-job .text-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#jobs .col-job h3 {
    color: #292728;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0;
}

#learn .head p {
    margin: 15px 0;
    font-weight: 400;
    color: #292728;
    font-size: 20px;
}

#free {
    background: #ff003c;
}

#free .head h2,
#free .head span {
    color: #fff;
}

#free .head p {
    font-size: 24px;
    color: #fff;
    font-weight: 300;
    line-height: normal;
    max-width: 80%;
    margin: 15px auto 15px;
}

#modules {
    background: #f4fcff;
}

.page #modules {
    background: #fff;
}

#modules .swiper-pagination-bullet {
    width: auto;
    height: auto;
    display: inline-block;
    background: #fce5eb;
    opacity: 1;
    color: #e1003c;
    padding: 20px 30px;
    margin-right: 20px;
    border-radius: 5px;
}

#modules .swiper-pagination-bullet-active {
    background: #e1003c;
    color: #fff;
}

#modules .swiper-pagination {
    position: relative;
    top: 0;
    left: -5%;
    width: 100%;
    bottom: unset;
    margin-bottom: 60px;
}

#modules .swiper {
    max-width: 90%;
    margin-right: 0;
}

#modules .swiper-slide {
    opacity: 0.5;
}

#modules .swiper-slide-active {
    opacity: 1;
}

#modules .module {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    margin: 10px 5px;
    border-radius: 15px;
}

#modules .module .col-text {
    background: #fff;
    padding: 30px 30px;
    font-weight: 300;
}

#modules .module .col-text h3 {
    color: #292728;
    font-size: 24px;
}

#modules .module .col-text ul {
    padding-inline-start: 30px;
}

#modules .module .col-text ul li {
    color: #292728;
    font-weight: 300;
    font-size: 15px;
}

#modules .module .col-text ul li::marker {
    color: #e1003c;
}

#learn .nav-pills .nav-link {
    position: relative;
    font-weight: 700;
    font-size: 20px;
    color: #292728;
    padding: 30px 30px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}

#learn .nav-pills .nav-link span {
    position: absolute;
    top: -20px;
    left: -20px;
    font-size: 24px;
    opacity: 0.4;
}

#learn .nav-pills .nav-link i {
    float: right;
}

#learn .nav-pills .nav-link.active,
#learn .nav-pills .show>.nav-link {
    color: #e1003c;
    background: transparent;
}

#learn .nav-pills .nav-link.active span {
    opacity: 1;
}

#learn .nav-pills .nav-link.disable {
    opacity: 0.4;
}

#learn .tab-content .col-content {
    border: 2px solid #e1003c;
    padding: 30px 50px;
    border-radius: 30px;
}

#blog {
    background: #f4fcff;
}

#blog .col-blog {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border-radius: 12px;
    overflow: hidden;
}

#blog .col-blog .text-info {
    text-align: left;
    padding: 20px 20px;
}

#blog .col-blog .text-info span {
    font-size: 12px;
    font-weight: 700;
    color: #e1003c;
}

#blog .col-blog .text-info h3 {
    margin: 10px 0 0 0;
    font-size: 16px;
    color: #292728;
    font-weight: 700;
    text-transform: uppercase;
}

#experiences .swiper-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
}

#experiences .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

#experiences .swiper-slide {
    background-position: center;
    background-size: cover;
}

#experiences .swiper-slide {
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    opacity: 0;
}

#experiences .swiper-slide.swiper-slide-visible {
    opacity: 1;
}

#experiences .swiper-slide .experience h3 {
    padding: 15px 30px;
    background: #1e145f;
    color: #fff;
    text-align: center;
    font-size: 25px;
    margin-bottom: 0;
}

#experiences .swiper-slide .experience p {
    margin-bottom: 0;
    padding: 50px 40px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}

#experiences .swiper-button-prev {
    left: 0;
}

#experiences .swiper-button-next {
    right: 0;
}

#experiences .swiper-button-prev:after {
    content: "\f0d9";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}

#experiences .swiper-button-next:after {
    content: "\f0da";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}

#options {
    position: relative;
    padding-bottom: 100px;
}

#options:before {
    content: "";
    background: #ff003b;
    width: 100%;
    height: 215px;
    position: absolute;
    top: 0;
    left: 0;
}

#options .head h2 {
    color: #fff;
}

#options .nav-tabs {
    border-bottom: 0;
}

#options .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: 0;
    font-weight: 700;
    font-size: 24px;
    color: #e1003c;
    background: #e9adbc;
}

#options .nav-tabs .nav-item.show .nav-link,
#options .nav-tabs .nav-link.active {
    background: #fff;
}

#options .tab-content {
    padding: 60px 30px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background: #fff;
}

#options .tab-content .text-info {
    height: 100%;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    text-align: center;
    padding: 35px 45px;
}

#options .tab-content .text-info h3 {
    font-size: 24px;
    color: #e1003c;
}

#options .tab-content .text-info p {
    font-size: 18px;
    color: #292728;
    margin-bottom: 0;
}

#specializations {
    background: #f4fcff;
}

#specializations .col-special {
    background: #fff;
    text-align: center;
    padding: 30px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100%;
    margin-bottom: 30px;
}

#specializations .col-special h3 {
    color: #292728;
    font-size: 24px;
    margin: 10px 0 0px;
}

#specializations .col-special span {
    color: #1e145f;
    font-weight: 400;
    font-size: 18px;
}

#specializations .col-special p {
    color: #292728;
    font-size: 18px;
    font-weight: 300;
    margin: 10px 0 0;
}

#events .text-info {
    padding: 50px 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#events .text-info .info {
    margin-bottom: 0;
}

#events .text-info .date strong {
    font-size: 50px;
    color: #e1003c;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    line-height: 1;
}

#events .text-info .date span {
    display: block;
    color: #292728;
    font-size: 24px;
    font-weight: 400;
}

#events .text-info .info h3 {
    color: #292728;
    font-size: 25px;
    font-weight: 700;
}

#events .text-info .info p {
    color: #292728;
    font-size: 16px;
    text-align: justify;
    font-weight: 300;
    margin-bottom: 10px;
}

#events .text-info .info .btn-danger {
    margin: 10px 0 20px 0;
    font-size: 16px;
}

#questions {
    background: #f4fcff;
}

.col-search {
    background: #fff;
}

.col-search.form-inline .btn {
    width: 10%;
    margin: 0;
    color: #e1003c;
}

.col-search.form-inline .form-control {
    width: 90%;
    border: 0;
    min-height: 70px;
}

.col-search.form-inline .form-control:focus {
    box-shadow: none;
}

.col-faq {
    height: 100%;
    padding: 30px 30px;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.col-faq h3 {
    font-weight: 700;
    color: #292728;
    font-size: 18px;
}

.col-faq p {
    font-weight: 300;
    color: #292728;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.col-faq p>i {
    color: #e1003c;
    font-size: 5px;
    margin-right: 5px;
}

.page-faq1 .col-faq {
    background: transparent;
    box-shadow: none;
    padding: 30px 0;
}

#accordionFaq {
    width: 95%;
    margin-left: auto;
}

#questions .accordion>.card {
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#questions .accordion>.card>.card-header {
    background: #fff;
    border: 0;
    margin-bottom: 1px;
    padding: 20px 45px;
}

#questions .accordion .btn {
    color: #292728;
    font-weight: 700;
    text-decoration: none;
    padding: 10px 0;
}

#questions .accordion .btn span {
    color: #e1003c;
}

#questions .accordion .btn i {
    float: right;
}

#questions .card-body {
    padding: 20px 45px 40px;
}

#questions .card-body p {
    font-weight: 300;
    font-size: 18px;
}

.page-blog header {
    background: #1e145f;
}

#blogtop {
    padding-top: 180px;
}

#blogtop .figure {
    position: relative;
}

#blogtop .figure img {
    border-radius: 5px;
}

#blogtop .figure-caption {
    position: absolute;
    padding: 30px 30px;
    text-align: left;
    bottom: 0;
    left: 0;
    width: 80%;
}

#blogtop .figure-caption h2 {
    color: #fff;
    font-size: 35px;
    margin-bottom: 10px;
    margin-top: 10px;
}

#blogtop .figure-caption p {
    color: #fff;
}

#blogtop span.tag {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    background: #ff003c;
    padding: 10px 10px;
    border-radius: 5px;
}

.col-recent.head {
    padding: 30px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100%;
    border-radius: 5px;
}

.col-recent.head h2 {
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    padding-bottom: 20px;
}

.col-recent.head h3 {
    color: #e1003c;
    font-weight: 300;
    font-size: 15px;
    text-align: left;
}

.col-recent.head p {
    color: #292728;
    font-weight: 700;
    font-size: 15px;
    text-align: left;
}

.col-recent .row-recent {
    padding-top: 25px;
    border-top: 1px solid #eeeeee;
}

.bloglist .head {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bloglist .head h2 {
    color: #292728;
    font-size: 35px;
    margin-bottom: 0;
}

.bloglist .link {
    color: #292728;
    font-size: 20px;
    font-weight: 300;
    float: right;
}

.col-blog {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.col-blog .figure {
    position: relative;
}

.col-blog .figure .taglogo {
    position: absolute;
    right: 0;
    bottom: 10%;
}

.col-blog .figure .figure-caption {
    padding: 20px 20px;
}

.col-blog .figure .figure-caption h2 {
    color: #292728;
    font-size: 19px;
    text-align: justify;
    font-weight: 500;
    margin-bottom: 15px;
}

.col-blog .figure .figure-caption p>span {
    color: #e1003c;
}

.page-blog main section:nth-child(odd) {
    background: #f4fcff;
}

#contact.sus .form-control {
    display: block;
    width: 30%;
    margin: 0 auto 20px;
    min-height: 50px;
    background: transparent;
    color: #fff;
}

#contact.sus .form-control:focus {
    box-shadow: none;
}

#contact.sus .form-control::placeholder {
    color: #fff;
    opacity: 1;
}

#contact.sus .btn-light {
    display: block;
    width: 30%;
    margin: auto;
    min-height: 50px;
}

#blogtop .head p>span {
    color: #e1003c;
    font-size: 15px;
}

#blogtop .head h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0;
}

.page-blog.d-blog main section:nth-child(odd) {
    background: #fff;
}

.page-blog.d-blog main section:nth-child(even) {
    background: #f4fcff;
}

.d-blog .body h2 {
    font-size: 35px;
    color: #292728;
    font-weight: 700;
}

.d-blog .body p {
    font-size: 18px;
    color: #292728;
    font-weight: 300;
}

.d-blog .body a {
    color: #e1003c;
}

.d-blog .body .blockquote {
    padding: 20px 40px 20px 40px;
    border-left: 2px solid #e1003c;
}

.d-blog .body .blockquote i {
    color: #e1003c;
}

.d-blog .body .blockquote p {
    color: #292728;
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
}

#page-cms {
    padding-top: 180px;
    background: #1b0c61;
}

#page-cms .head {
    padding-bottom: 0;
}

#page-cms .head h2 {
    color: #fff;
}

#page-cms-cnt h3 {
    font-size: 22px;
    color: #1e145f;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
}

#page-cms-cnt h4 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
}

#page-cms-cnt p {
    text-align: justify;
    font-size: 16px;
    color: #292728;
    font-weight: 300;
}

#page-cms-cnt ul li {
    text-align: justify;
    font-size: 16px;
    color: #292728;
    font-weight: 300;
    margin-bottom: 5px;
}

#page-cms-cnt a {
    color: #ff003c;
}

#page-cms-cnt ul li::marker {
    color: #ff003c;
}

.loading {
    position: relative;
}

.loading:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    z-index: 2;
}

.loading:after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 3px solid red;
    border-top-color: transparent !important;
    z-index: 3;
    animation: loader-spin 800ms infinite linear;
}

.btn-loading {
    color: transparent !important;
}

.btn-loading:after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 16px;
    width: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-top-color: transparent !important;
    animation: loader-spin 800ms infinite linear;
}

#method .nav-pills .nav-link {
    border: 3px solid #e1003c;
    padding: 10px 25px;
    display: block;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 18px;
    color: #e1003c;
}

#method .nav-pills .nav-link.active {
    background: #e1003c;
    color: #fff;
}

#pills-second h3 {
    font-size: 25px;
    margin-bottom: 20px;
}

#pills-second ul li {
    padding-left: 10px;
    margin-bottom: 15px;
}

#pills-second ul li::marker {
    content: "\f058";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: #e1003c;
}

@keyframes loader-spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes tab-loader {
    0% {
        left: 0;
        width: 20px;
    }
    50% {
        left: calc(100% - 20px);
        width: 20px;
    }
    100% {
        left: 0;
        width: 20px;
    }
}

.error-message {
    line-height: 20px;
    display: block;
    margin: 4px 0 -5px;
    color: red;
}

#modalProgram .modal-header {
    background: #1b0c61;
}

#modalProgram .modal-header .modal-title,
#modalProgram .modal-header .close {
    color: #fff;
}

#modalProgram .form-control {
    height: 50px;
    border-radius: 0.3rem;
}

#modalProgram .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #1b0c61;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgb(27 12 97 / 64%);
}

#modalProgram textarea.form-control {
    height: auto;
}

#students .col-student {
    padding: 30px 50px;
    border-radius: 5px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    height: 100%;
}

#students .col-student .figure-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#students .col-student .figure-caption img {
    margin-right: 15px;
    border-radius: 50%;
}

#students .col-student .figure-caption .text-info {
    text-align: left;
}

#students .col-student .figure-caption .text-info h3 {
    font-weight: 900;
    color: #ff003c;
    font-size: 20px;
    margin-bottom: 5px;
}

#students .col-student .figure-caption .text-info span {
    color: #495057;
    font-size: 18px;
    text-transform: uppercase;
}

#students .col-student .figure-caption .text-info p {
    font-weight: 900;
    color: #495057;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 0;
}

#students .col-student .content p {
    font-weight: 300;
}

#students .col-student .cv span {
    display: inline-block;
    background-color: rgb(243, 243, 243);
    color: rgb(0, 0, 0);
    border-radius: 8px;
    padding: 7px 13px;
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
    margin: 5px 1px;
    border: 0px;
}

#modalAplica .contenido {
    max-width: 800px;
    margin: 0 auto
}

#modalAplica .modal-body {
    padding: 3rem;
}

#modalAplica .modal-header {
    border: 0;
}

#modalAplica .close {
    font-size: 45px;
}

#modalAplica label {
    font-weight: bold;
}

#modalAplica .form-control {
    height: calc(2em + 0.75rem + 2px);
}

#modalAplica .modal-content {
    padding: 3rem;
}

#modalAplica .bloque {
    overflow: hidden;
}

#modalAplica .bloque .imagen {
    float: left;
    margin-right: 20px;
}

#modalAplica .bloque .texto {
    overflow: hidden;
}

#modalAplica .step-2 {
    display: none;
}

#modalAplica .modal-xl {
    max-width: 1700px;
}

#modalAplica h4 {
    margin-bottom: 4rem;
}

.modal-open .modal {
    padding: 0 !important;
}

@media screen and (min-width: 1920px) {
    header,
    main,
    footer,
    #copyright {
        max-width: 1920px;
        margin: auto;
    }
}

@media screen and (max-width: 1560px) {
    #slider .title {
        font-size: 50px;
        margin-bottom: 15px;
    }
    #slider p.subtitle {
        font-size: 25px;
        margin-bottom: 15px;
    }
    #why-us .container .row {
        padding: 50px 60px;
        top: -100px;
    }
}

@media screen and (max-width: 1500px) {
    .page-general .breadcrumb.v2 .head h2 {
        font-size: 48px;
        max-width: 100%;
    }
}

@media screen and (max-width: 1440px) {
    .navbar-dark .navbar-nav .nav-link {
        font-size: 18px;
        padding: 0 10px;
    }
    .call .btn.btn-danger {
        font-size: 18px;
        padding: 7px 15px;
    }
    .call .btn.btn-light {
        font-size: 18px;
        padding: 7px 15px;
    }
    #slider .carousel-item .carousel-caption .col-lg-6 {
        margin-top: 0;
    }
}

@media screen and (max-width: 1400px) {
    #experiences .swiper-slide .experience h3 {
        font-size: 20px;
    }
    #experiences .swiper-slide .experience p {
        padding: 40px 30px;
        font-size: 18px;
    }
    .breadcrumb.v2 .head p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 992px) {
    .breadcrumb .container {
        top: 55%;
    }
    .page .breadcrumb.v2 .head .title {
        max-width: 100%;
        margin-bottom: 15px;
    }
    .page .breadcrumb.v2 .btn-danger {
        width: 55%;
    }
}

@media screen and (max-width: 1250px) {
    .breadcrumb.v2 .head p {
        margin: 10px 0 10px;
        font-size: 16px;
        text-align: left;
    }
}

@media screen and (max-width: 1200px) {
    .navbar-dark .navbar-nav .nav-link {
        font-size: 16px;
    }
    #slider .title {
        font-size: 32px;
    }
    #slider p.subtitle {
        font-size: 18px;
    }
    #slider .carousel-item .carousel-caption .col-lg-6 {
        margin-top: 0;
    }
    .call .btn {
        padding: 8px 15px;
        font-size: 14px;
    }
    .head {
        padding-bottom: 30px;
    }
    .head h2,
    .breadcrumb .head .title {
        font-size: 38px;
    }
    .head span {
        font-size: 20px;
    }
    #why-us .container .row {
        padding: 40px 40px;
        top: -100px;
    }
    #why-us .figure .figure-caption h3 {
        font-size: 20px;
    }
    .page-aboutus .breadcrumb .head .title {
        font-size: 28px;
    }
    #start .head {
        width: 100%;
    }
}

@media only screen and (max-width: 1100px) and (min-width: 992px) {
    .call .btn.btn-danger,
    .call .btn.btn-light,
    .breadcrumb.v2 .head p {
        display: none;
    }
    .page-general .breadcrumb.v2 .head h2 {
        font-size: 38px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 991px) {
    header .container {
        max-width: 100%;
    }
    #why-us {
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    #why-us .container .row {
        position: relative;
        top: 0;
    }
    #why-us .figure .figure-caption h3 {
        font-size: 18px;
    }
    #why-us .figure .figure-caption p {
        font-size: 15px;
    }
    #testimonials .testimonial {
        width: 90%;
    }
    #countries .head,
    #countries .col-lg-6 {
        text-align: center;
    }
    #methodology .tab-content h3 {
        margin-top: 50px;
        text-align: center;
    }
    #methodology .tab-content h3:after {
        left: 50%;
        transform: translateX(-50%);
    }
    #contact .container .row:before {
        width: 100%;
    }
    #contact .col-lg-6 {
        text-align: center;
    }
    #contact .head {
        text-align: center;
        margin-top: 30px;
    }
    #copyright .list-group-item,
    #copyright .list-group-item a {
        font-size: 16px;
    }
    .navbar-collapse {
        padding: 35px 25px 40px;
        background: #1b0c61;
    }
    .navbar-dark .navbar-nav .nav-link,
    .call {
        padding: 20px 0 0 0;
    }
    #news .item {
        text-align: center;
    }
    footer .input-group-prepend {
        width: 100%;
    }
    footer .col-lg-3.col-md-6.col-sm-6 {
        margin-bottom: 30px;
    }
    .padding {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #partners {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #process .col-process {
        margin-right: 0;
    }
    .page-aboutus .breadcrumb .head .title {
        font-size: 25px;
        max-width: 100%;
    }
    #options {
        padding-top: 100px;
    }
    #options:before {
        height: 100%;
    }
    #options .tab-content .text-info {
        margin-bottom: 20px;
    }
    #options .tab-content .col-opt-1 .text-info:before {
        position: absolute;
        right: unset;
        top: unset;
        left: 50%;
        bottom: -40px;
        transform: translateX(-50%);
    }
    #process .head,
    #mode .head,
    .breadcrumb.v2 .head .title {
        max-width: 100%;
    }
    .page #contact .bg-tred {
        padding-left: 20px;
        padding-right: 20px;
    }
    .breadcrumb.v2 .head {
        text-align: center;
    }
    #jobs .col-job:nth-child(5),
    #jobs .col-job:nth-child(6) {
        border-bottom: 2px solid #efefef;
    }
    .page-career #testimonials .head h2 {
        width: 100%;
    }
    .page-career #testimonials .head p {
        max-width: 95%;
        margin: 0 auto 15px;
        font-size: 20px;
    }
    #learn .nav-pills .nav-link span {
        left: 0;
        background: #fff;
    }
    .col-nav-cnt {
        padding-top: 60px;
    }
    #learn .tab-content .col-content:before {
        left: -10px;
        top: 0;
        transform: translateY(-50%);
    }
    #learn .tab-content .col-content {
        padding: 40px 30px;
    }
    #free .head h2 {
        width: 100%;
        font-size: 35px;
        margin: 10px 0;
        text-align: center;
    }
    #free .head p {
        width: 100%;
        text-align: center;
        font-size: 24px;
    }
    #free .head span {
        display: block;
        text-align: center;
    }
    #modules .swiper {
        max-width: 95%;
        margin: auto;
    }
    #modules .swiper-pagination {
        left: 0;
    }
    #modules .swiper-pagination-bullet {
        width: 20%;
    }
    #contact.sus .form-control,
    #contact.sus .btn-light {
        width: 80%;
    }
    .date {
        padding-bottom: 20px;
    }
    #events .text-info .date span {
        display: inline-block;
    }
    #blogtop .figure-caption {
        width: 100%;
    }
    #blogtop .figure-caption h2 {
        font-size: 28px;
    }
    .col-faq h3 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .page-faq1 .col-faq {
        text-align: center;
    }
    .col-faq p {
        justify-content: center;
    }
    #accordionFaq {
        width: 100%;
    }
    .breadcrumb.v2 .head p {
        text-align: center;
    }
    br {
        display: none;
    }
    .page .breadcrumb.v2 .btn-danger,
    .btn-download {
        width: 60%;
        margin: auto;
    }
    .page-holbies .breadcrumb.v2 .head .title {
        font-size: 40px;
    }
    #skills .col-skill .text-info ul li {
        width: 70%;
        float: left;
        text-align: center;
        margin-bottom: 5px;
    }
    #modalAplica .modal-content,
    #modalAplica .modal-body {
        padding: 1rem;
    }
    #modalAplica h2 {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 767px) {
    #why-us .container .row {
        justify-content: flex-start;
    }
    .head h2,
    .breadcrumb .head .title {
        font-size: 32px;
    }
    #methodology .nav-methodology .nav-tabs .nav-link>strong {
        display: none;
    }
    #partners .item {
        padding: 5px 5px 5px 5px;
        text-align: center;
    }
    #modules .swiper-pagination-bullet {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    #slider .carousel-item .carousel-caption {
        width: 95%;
        padding-top: 70px;
    }
    #why-us .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
    #partners .item {
        width: 50%;
    }
    #news .item {
        text-align: center;
        width: 50%;
        margin-bottom: 15px;
    }
    #methodology .tab-content ul {
        padding-inline-start: 20px;
    }
    #partners .head h2 {
        font-size: 24px;
    }
    .head h2 {
        font-size: 24px;
    }
    #slider .title {
        font-size: 25px;
    }
    #blogtop .figure-caption h2 {
        font-size: 25px;
    }
    #blogtop span.tag {
        display: none;
    }
}

@media screen and (max-width: 520px) {
    .page-general .breadcrumb.v2 .head h2 {
        font-size: 32px;
        max-width: 100%;
    }
    .col-search.form-inline .form-control {
        width: 80%;
    }
    .bloglist .head h2 {
        font-size: 24px;
    }
    .bloglist .link {
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    #ourteam .swiper-slide {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    #slider p.subtitle {
        display: none;
    }
    #slider p.info {
        margin-bottom: 15px;
    }
    #slider .btn {
        padding: 4px 25px;
        font-weight: 600;
        font-size: 18px;
    }
    .breadcrumb .container {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .breadcrumb.v2 .head .title,
    .page-holbies .breadcrumb.v2 .head .title,
    .page-general .breadcrumb.v2 .head h2 {
        font-size: 32px;
        max-width: 100%;
        text-align: center;
    }
    .page-adm .breadcrumb.v2 .head p {
        margin: 20px 0;
        text-align: center;
    }
    .breadcrumb.v2 .head p {
        font-size: 18px;
        text-align: center;
        margin: 10px 0 15px;
    }
    .page .breadcrumb.v2 .btn-danger {
        display: block;
        width: 85%;
        margin: auto;
        font-size: 16px;
    }
    .btn-download {
        width: 60%;
        margin: auto;
    }
    .page-aboutus .breadcrumb .head span {
        font-size: 28px;
    }
    .page-aboutus .breadcrumb .head .title {
        font-size: 18px;
        max-width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .breadcrumb.v2 .head .title,
    .page-general .breadcrumb.v2 .head h2 {
        font-size: 22px;
        max-width: 100%;
        text-align: center;
    }
    .breadcrumb.v2 .head p {
        font-size: 15px;
    }
}

@media screen and (max-width: 380px) {
    .call .btn.btn-danger {
        margin-bottom: 15px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
        display: none;
    }
}
