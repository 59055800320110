.MPDTOneJobeEmpresa {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 160px;
  min-width: 900px;
}


.MPDTNoDisponible {
  color: red;
  font-size:x-large;
}

.MPDTDisponible {
  color: rgb(37, 37, 243);
  font-size: x-large;
}


.MPDTGroupOfButtons {
  margin-top: 10px;
}

/*Para el grupo de botones*/
.MPDTGroupOfButtons button {
  /*color: black; /* White text */
  padding: 10px 24px; /* Some padding */
}

.MPDTVerButton {
  background-color: #eaf513; /* Green background */
  border: 1px solid black; /* Green border */
}

.MPDTVerButton:hover {
  cursor:pointer;
  transform: scale(1.1);
}

.MPDTEditarButton {
  background-color:forestgreen; /* Green background */
  border: 1px solid black; /* Green border */
}

.MPDTEditarButton:hover {
  cursor:pointer;
  transform: scale(1.1);
}

.MPDTEliminarButton {
  background-color:rgb(233, 69, 69); /* Green background */
  border: 1px solid black; /* Green border */
}

.MPDTEliminarButton:hover {
  cursor:pointer;
  transform: scale(1.1);
}

.MPDTPostulantesButton {
  background-color:lavenderblush; /* Green background */
  border: 1px solid black; /* Green border */
}

.MPDTPostulantesButton:hover {
  cursor:pointer;
  transform: scale(1.1);
}
/*Para el grupo de botones*/