.container-profile-job {
  display: grid;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .container-profile-job {
    width: 500px;
  }
}

.profile-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.container-profile-job .container-form {
  width: 700px;
  border-radius: 5px;
  border: 2px solid #000;
}

.form-control {
  position: relative;
}

.form-control label {
  margin: 5px 0px;
}

.form-control .form-control {
  padding-right: 30px;
}

.usericon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.usericon-form {
  width: 150px;
  height: 150px;
  border:2px solid #fff;
  box-shadow: 0px 2px 3px 3px #ccc;
  color:white;
  font-size:50px;
  border-radius: 10px;
}

.form-control.success .input {
  border-color: #2ecc71;
}

.form-control i {
  visibility: hidden;
  position: absolute;
  top: 42px;
  right: 20px;
}

.form-control.success i.fa-check-circle {
  color: #2ecc71;
  visibility: visible;
}

.form-control.error i.fa-exclamation-circle {
  padding-top: 9px;
  color: #e74c3c;
  visibility: visible;
}

.form-control small {
  color: #e74c3c;
  font-weight: 700;
  visibility: hidden;
}

.form-control.error small {
  visibility: visible;
}

.container-selectFile .box-photo {
  display: flex;
  justify-content: space-between;
}

.container-selectFile .box-photo input {
  width: 100%;
}

.form-control .ql-editor {
  min-height: 700px;
  background-color: white;
}

.form-control .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
}

/* Quill default font size */

.ql-container {
  font-size: 8px;
}

/* Quill font size label in toolbar*/

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: 'Normal';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: 'Large';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: 'Huge';
  font-size: 18px !important;
}