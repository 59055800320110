.FilterContainer {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

/*
.FieldTextFilterContainer {
  margin-top: 5px;
}

.ChipsFilterContainer {
  margin-top: 5px;
}

.RadioFiltersContainer {
  margin-top: 5px;
}

.ConfirmationButtons {
  margin-top: 5px;
}
*/