.InicioContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url("./fondo\ land-01.png");
}


.logoholbertonInicio {
  position: relative;
  top: 10%;
  left: 5%;
}
/*
.LoginButonInicio {
  height: 745px;
  display: flex;  
  justify-content: center;  
  align-items: center;  
}
*/
.backButtonInicio {
  position: relative;
  top: 15%;
  left: 5%;
}
.LoginButonInicio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}