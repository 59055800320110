* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}

.PDTEPuestosDeTrabajoEstudianteContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction : column;
}

.PDTENumeroDeEmpleos {
  color: #1B0C61;
  margin-left: 40px;
  margin-top: 25px;
}
