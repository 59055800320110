#div-title-background {
    display: none;
    justify-content: center;
    align-items: center;
}

.title-background {
    padding-bottom: 60%;
    font-size: 100px;
    font-weight: 700;
    writing-mode: vertical-rl;
    /*position: absolute;*/
    text-align: end;
    transform: rotate(180deg);
    height: 250vh;
    color: #000;
    left: 0;
}

.mundo {
    font-size: 100px;
    font-weight: 700;
    writing-mode: vertical-rl;
    text-align: end;
    transform: rotate(180deg);
    height: 250vh;
    color: #fff;
}

.padding-main {
    background: url(../images/fondoholberton.jpeg) no-repeat; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-height: 1300px;
}

.padding-main .container .container-content-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.padding-main .container .row {
    background: #fff;
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.title-buttons {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
    padding: 20px;
}

.button-skill {
    color: #000;
    border-radius: 8px;
    padding: 7px 13px;
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
    margin: 5px 1px;
    border: 0;
}

@media (min-width: 1700px) {
    #div-title-background {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 2%;
    }
    .title-background {
        padding-bottom: 60%;
        font-size: 100px;
        font-weight: 700;
        writing-mode: vertical-rl;
        /*position: absolute;*/
        text-align: end;
        transform: rotate(180deg);
        height: 250vh;
        color: #000;
        left: 0;
    }
  }

  @media (min-width: 1800px) {
    #div-title-background {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 4%;
    }
    .title-background {
        padding-bottom: 60%;
        font-size: 100px;
        font-weight: 700;
        writing-mode: vertical-rl;
        /*position: absolute;*/
        text-align: end;
        transform: rotate(180deg);
        height: 250vh;
        color: #000;
        left: 0;
    }
  }

@media (min-width: 1600px) and (max-width: 1700px) {
  #div-title-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 1%;
  }
}

.list-students-section ul {
    padding-left: 0;
}

.div-paginate-student-numbers {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.PDTENumeroDeEstudiantes {
    position: absolute;
    color: white;
}

.videoBox {
    width: 410px;
    height: 300px;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: 50%;
}

.videoCardMedia {
    width: 100%;
    border: none;
    cursor: pointer;
    height: 100%;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    justify-content: center;
    display: flex !important;
}

#grid-buttons-english {
    flex-wrap: nowrap;
}

#grid-buttons {
    width: auto;

}

/* Accordion border radius top and bottom */

.Accordion-FAQ {
    width: 100%;
}

.Accordion-FAQ li {
    margin-left: 15px;
    list-style-type: '- ';
}

.Accordion-FAQ li span {
    font-weight: 600;
}

.accordion-item:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}