.MisPuestosDeTrabajoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction : column;
}

.NumeroDeMisEmpleos {
  margin-top: 40px;
}
