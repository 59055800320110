.PostulantesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction : column;
}

.PTitleContainer {
  padding-left: 50px;
  margin-top: 30px;
  width: 100%;
}
