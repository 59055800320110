.MPOneJob {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  border-radius: 15px;
  border: 2px solid black;
  flex-direction: column;
  padding: 10px;
}

.MPOneJob:hover {
  cursor:pointer;
  transform: scale(1.05);
}

.MPNoDisponible {
  color: red;
  font-size:x-large;
}

.MPDisponible {
  color: rgb(37, 37, 243);
  font-size: x-large;
}

#quill-html-container-pos-student p{
  font-size: 16px;
  margin-bottom: 0;
}