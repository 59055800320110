.LoginEstudiantesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction : column;
}

.BoxLogin {
  width: 30%;
}
@media (max-width: 1200px) and (min-width: 500px){
  .BoxLogin {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .BoxLogin {
    width: 90%;
  }
}

.IconHolbLogin {
  margin: 20px;
}