.MisPostulacionesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction : column;
}


.NumeroDeEmpleosPostulados {
  margin-top: 40px;
  color: #1B0C61;
}