.pagination {
  border-radius: 10px;
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination > li > a{
  color: black;
  margin: 8px;
  padding: 5px 13px;
  outline: none;
  cursor: pointer;
}

.page-item.active .page-link {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #26108c;
  border: 0;
  font-weight: 700;
}

.page-link{
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: white;
  border: 0;
  font-weight: 700;
}

.page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

/*
.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}


.pagination>li.active>a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

*/

/* Style the active class (and buttons on mouse-over) 
.pagination > li > a:hover {
  background-color:  #218838;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
    padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none!important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px!important;
  border-top-right-radius: 4px;
  display: none!important;
}
*/

/*
  position:fixed;
  left: 70%;
  top: 80px;
*/




/*
.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
*/
