@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
footer {
    background: #fff;
    padding-top: 80px;
}

footer h4 {
    margin-bottom: 30px;
    color: #ff003c;
    font-weight: 900;
    font-size: 24px;
}

footer .col-lg-3:first-of-type h4 {
    color: #292728;
}

footer .input-group-prepend {
    display: flex;
}

footer .input-group>.input-group-prepend>.form-control {
    height: 50px;
    border: 0;
    background: #f1f2f2;
}

footer .input-group>.input-group-prepend>.btn.btn-danger {
    display: flex;
    margin-right: 0;
    margin-left: 10px;
    padding: 0 15px;
    border-radius: 5px !important;
    color: #fff;
    height: 50px;
    width: 50px;
    border: 0;
}

footer ul {
    padding-inline-start: 0;
    list-style: none;
}

footer ul li {
    margin-bottom: 20px;
    font-weight: 300;
}

footer ul li a {
    color: #292728;
    text-decoration: none;
}

footer ul li a:hover {
    color: #ff003c;
}

footer .col-lg-3:last-of-type i {
    font-size: 16px;
}

footer ul.social-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

footer ul.social-info li a {
    width: 50px;
    height: 50px;
    display: flex;
    background: #ff003c;
    margin-right: 10px;
    color: #fff !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

@media screen and (min-width: 1920px) {
    .header-wrap,
    main,
    footer,
    #copyright {
        max-width: 1920px;
        margin: auto;
    }
}

@media screen and (max-width: 991px) {
    footer .input-group-prepend {
        width: 100%;
    }

    footer .col-lg-3.col-md-6.col-sm-6 {
        margin-bottom: 30px;
    }
}
